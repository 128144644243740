<template>
  <div class="support-bloggers-body" :class="$i18n.locale">
    <canvas class="bg" ref="canvas" />
    <div class="text">
      <p class="title">{{$t("supportBloggers.title")}}</p>
      <p class="title">{{$t("supportBloggers.subTitle")}}</p>
      <p class="description">{{$t("supportBloggers.content")}}</p>
      <img class="pay" src="@/assets/img/pay.png">
    </div>
  </div>
</template>

<script>
import Player from 'silent-film-player';
export default {
  name: "supportBloggers",
  components: {
  },
  mounted() {
    const {
      $refs: { canvas },
    } = this;
    // 这里要在线地址
    window.player = new Player('/media/support-bg.ts', {
      canvas,
      loop: true,
      autoplay: true,
      disableWebAssembly: true,
      // 分片大小
      chunkSize: 1 * 1024 * 1024,
      videoBufferSize: 512 * 1024,
    });
  }
}
</script>

<style lang="scss" scoped>
.support-bloggers-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  display: flex;
  align-items: center;

  .bg {
    position: absolute;
    z-index: 10;
    width: 100%;
  }

  .text {
    position: relative;
    z-index: 50;
    background: transparent;
    font-size: 1vw;
    margin-left: 6vw;
    width: 26em;

    * {
      background: transparent;
      color: white;
      font-family: Helvetica Neue, Helvetica, Arial, 「苹方-简」, 「Microsoft YaHei」, 「微软雅黑」, sans-serif;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .title {
      font-size: 1.7em;
      font-weight: bold;
      line-height: 1.5em;
      letter-spacing: 0.2em;
    }

    .description {
      margin: 1.8em 0;
      line-height: 1.5em;
      letter-spacing: 0.1em;
    }

    .pay {

      width: 70%;
      margin-bottom: 2em;
    }
  }
}

@media (max-width: 740px) {
  .support-bloggers-body {
    min-height: 300px;
    height: 65vh;
    position: relative;

    .text {

      font-size: 3vw;
    }
  }
  .support-bloggers-body.en{
    height: 75vh;
  }
}
</style>